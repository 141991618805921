import React from "react";
import {
  Segment,
  Header,
  Icon,
} from "semantic-ui-react";
import { PropTypes } from "prop-types";
import { i18next } from "@translations/invenio_berd/i18next";


export const EmptyResultsSegment = ({
  extraContent,
}) => {
  return (
    <Segment 
      placeholder 
      textAlign="center"
    >
      <Header icon>
        <Icon name="search" />
        {extraContent ?? i18next.t("No results found.")}
      </Header>
    </Segment>
  );
};

EmptyResultsSegment.propTypes = {
  extraContent: PropTypes.string,
};
