import React from "react";
import { Card } from "semantic-ui-react";
import { RecordCard } from "./RecordCard";


export const RecordCardGroup = ({
  results,
  itemsPerRow,
  ...props
}) => {
  // ResultsGrid.container receives components as `results`, unpack the records:
  const records = results.map(result => result.props.result)

  return (
    <Card.Group 
      itemsPerRow={itemsPerRow}
      doubling
      stackable
    >
      {records.map((record, i) => <RecordCard key={i} record={record} />)}
    </Card.Group>
  );
};