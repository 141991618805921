import React from "react";
import _truncate from "lodash/truncate";
import { 
  Card,
  Label,
} from 'semantic-ui-react';


export const RecordCard = ({
  record
}) => {
  const cleanTags = (str) => {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const id = record.id;
  const title = _truncate(
    record.metadata.title,
    {length: 150}
  );
  const creators = _truncate(
    record.metadata.creators
      .map(creator => creator.person_or_org.name)
      .join("; "),
    {length: 50}
  );
  const description = _truncate(
    cleanTags(record.metadata.description ?? ""), 
    {length: 300}
  );

  const resourceTypeLabel = record.metadata.resource_type.title?.en;

  return ( 
    <Card 
      fluid 
      href={`/records/${id}`}
    >
      <Card.Content>
        
        {resourceTypeLabel && (
          <Label 
            size="tiny" 
            className="neutral"
          >
            {resourceTypeLabel}
          </Label>
       )}
        <Card.Header>{title}</Card.Header>
        <Card.Meta>{creators}</Card.Meta>
        <Card.Description>{description}</Card.Description>
      </Card.Content>
    </Card>
  );
};
