import React from "react";
import { 
  ResultsGrid, 
  Pagination,
  withState,
} from "react-searchkit";
import { 
  Grid,
  Button, 
  Icon,
} from "semantic-ui-react";
import PropTypes from "prop-types";


const _ExploreWidgetResults = ({
  itemsToShow,
  showPagination,
  showMoreLabel,
  showMoreLink,
  showMoreAmount,
  currentResultsState,
}) => {

  const content = (<>
    <ResultsGrid resultsPerRow={itemsToShow}/>
    {showPagination && (
      <Grid style={{ padding: "2em 0" }} verticalAlign="middle" textAlign="center">
        <Pagination options={{
          // hide first and last arrows:
          showFirst: false,
          showLast: false,
          // hide page items except active page (which needs to be hidden via CSS):
          boundaryRangeCount: 0,
          siblingRangeCount: 0,
          showEllipsis: false,
        }} />
      </Grid>
    )}
  </>);

  const totalResults = currentResultsState.data.total;

  const hasShowMoreLabel = showMoreLabel && showMoreLink && totalResults > itemsToShow;

  return hasShowMoreLabel ? (
    <Grid>
      <Grid.Column computer="13" tablet="16" mobile="16">
        {content}
      </Grid.Column>
      <Grid.Column textAlign="center" verticalAlign="middle" computer="3" tablet="16" mobile="16">
        <Button 
          className="show-more"
          size="large"
          icon 
          labelPosition="left"
          as="a" 
          href={showMoreLink} 
        >
          {showMoreAmount ? `${showMoreLabel} (${totalResults})` : showMoreLabel}
          <Icon name="right arrow" />
        </Button>
      </Grid.Column>
    </Grid>
  ) : content;
};

export const ExploreWidgetResults = withState(_ExploreWidgetResults);

ExploreWidgetResults.propTypes = {
  itemsToShow: PropTypes.number.isRequired,
  showPagination: PropTypes.bool.isRequired,
  showMoreLabel: PropTypes.string,
  showMoreLink: PropTypes.string,
  showMoreAmount: PropTypes.bool,
};

ExploreWidgetResults.defaultProps = {
  showMoreLabel: undefined,
  showMoreLink: undefined,
  showMoreAmount: false,
}
