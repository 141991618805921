import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { 
  Icon,
  Container,
  Menu,
  Segment,
} from 'semantic-ui-react';
import {
  ReactSearchKit, 
  EmptyResults,
  Error,
  ResultsLoader,
  InvenioSearchApi, 
  withState,
} from "react-searchkit";
import { currentHost } from "../../records/related_resources/util";
import { OverridableContext } from "react-overridable";
import _truncate from "lodash/truncate";
import { overriddenComponents } from "./ExploreWidget";
import { ExploreWidgetResults } from "./ExploreWidgetResults";



/* TODO:
  - debug `findDOMNode is deprecated in StrictMode` warning;
    this is triggered by the use of slick-carousel but seems harmless ATM
*/


const createQueryState = (queryString, itemsToShow) => ({
  queryString: queryString, 
  sortBy: "recent",
  sortOrder: "asc", 
  layout: "list",
  page: 1,
  size: itemsToShow,
});

/**
 * Updates the query state when `queryString` changes, 
 * triggering a new query. This effectively 
 * updates the list of records when a new tab is selected.
 */
const _QueryStateUpdater = ({
  updateQueryState,
  queryString,
  itemsToShow
}) => {

  const [currentQueryString, setCurrentQueryString] = useState(queryString);

  useEffect(() => {
    if (queryString !== currentQueryString) {
      setCurrentQueryString(queryString);
      updateQueryState(createQueryState(queryString, itemsToShow));
    }
  }, [queryString]);

  return (null);
}


const QueryStateUpdater = withState(_QueryStateUpdater);


export const MenuExploreWidget = ({
  menuEntries,
  itemsToShow
}) => {

  const [activeItem, setActiveItem] = useState(menuEntries[0]);

  const handleItemClick = (e, {item}) => setActiveItem(item);

  const searchApi = new InvenioSearchApi({
    axios: {
      url: `${currentHost()}/api/records`,
      timeout: 5000,
    },
  });

  return (
    <Container className="menu-explore-widget">
      <Menu 
        className="explore-header"
        icon="labeled"
        secondary
      >
        {menuEntries.map((item, i) => (
          <Menu.Item 
            key={i}
            name={item.id}
            item={item}
            link
            active={activeItem.id === item.id}
            onClick={handleItemClick}
          >
            <Icon name={item.icon}/> 
            {item.title}
          </Menu.Item>
        ))}
      </Menu>
      <Segment
        attached="bottom"
        className="explore-body"
      >
        <ReactSearchKit
          searchApi={searchApi}
          initialQueryState={createQueryState(activeItem.queryString, itemsToShow)}
          urlHandlerApi={{ enabled: false }} 
        >
          <OverridableContext.Provider value={overriddenComponents}>
            <ResultsLoader>
              <QueryStateUpdater 
                queryString={activeItem.queryString}
                itemsToShow={itemsToShow}
              />
              <EmptyResults />
              <Error />
              <ExploreWidgetResults 
                itemsToShow={itemsToShow} 
                showPagination={true}
              />
            </ResultsLoader>
          </OverridableContext.Provider>
        </ReactSearchKit>
      </Segment>
    </Container>
  );
};


MenuExploreWidget.propTypes = {
  /* Entries to show in the widget header */
  menuEntries: PropTypes.arrayOf(PropTypes.shape({
    /* Entry ID, must be unique */
    id: PropTypes.string,
    /* Entry icon, shown above the title */
    icon: PropTypes.string,
    /* Entry title */
    title: PropTypes.string,
    /* The query to be triggered when the menu entry is active */
    queryString: PropTypes.string,
  })),
  /* The number of records to be shown in the widget body */
  itemsToShow: PropTypes.number,
};

MenuExploreWidget.defaultProps = {
  itemsToShow: 4,
};