import React from "react";
import PropTypes from "prop-types";
import {
  Container,
} from "semantic-ui-react";
import {
  ReactSearchKit, 
  EmptyResults,
  Error,
  ResultsLoader,
  InvenioSearchApi, 
} from "react-searchkit";
import { OverridableContext } from "react-overridable";
import { ExploreWidgetResults } from "./ExploreWidgetResults";
import { RecordCardGroup } from "./RecordCardGroup";
import { EmptyResultsSegment } from "./EmptyResultsSegment";
import { currentHost } from "../../records/related_resources/util";


export const overriddenComponents = {
  "ResultsGrid.container": RecordCardGroup,
  "EmptyResults.element": EmptyResultsSegment,
};

export const ExploreWidget = ({
  itemsToShow,
  queryString,
  sortBy,
  showMoreLabel,
  showMoreLink,
  showMoreAmount,
  noResultsText,
}) => {
  const searchApi = new InvenioSearchApi({
    axios: {
      url: `${currentHost()}/api/records`,
      timeout: 5000,
    },
  });

  const queryState = {
    queryString: queryString, 
    sortBy: sortBy,
    sortOrder: "asc", 
    layout: "list",
    page: 1,
    size: itemsToShow,
  };

  return (
    <Container className="explore-widget">
      <ReactSearchKit
          searchApi={searchApi}
          initialQueryState={queryState}
          urlHandlerApi={{ enabled: false }} 
        >
          <OverridableContext.Provider value={overriddenComponents}>
            <ResultsLoader>
              <EmptyResults 
                extraContent={noResultsText} 
              />
              <Error />
              <ExploreWidgetResults 
                itemsToShow={itemsToShow} 
                showPagination={false}
                showMoreLabel={showMoreLabel}
                showMoreLink={showMoreLink}
                showMoreAmount={showMoreAmount}
              />
            </ResultsLoader>
          </OverridableContext.Provider>
        </ReactSearchKit>
    </Container>
  );
};


ExploreWidget.propTypes = {
  /* How many items to show at the same time in the widget */
  itemsToShow: PropTypes.number.isRequired,
  /* The query string for fetching records to be shown in the widget */
  queryString: PropTypes.string.isRequired,
  /* What sort method should be used for listing the records */
  sortBy: PropTypes.string,
  /* If this is set, there will be a button with the given text to the right of the records that can be used
  for forwarding the user to a list of all relevant records (e.g. in the search results).
  The button is displayed only if the total number of records exceeds the number of records already shown in the widget. */
  showMoreLabel: PropTypes.string,
  /* The link that the user gets forwarded to when clicking the "show more" button */
  showMoreLink: PropTypes.string,
  /* Whether to show the total amount of found items (in parentheses) in the "show more" button */
  showMoreAmount: PropTypes.bool,
  /* Text to be shown if the query didn't return any records. */
  noResultsText: PropTypes.string,
};

ExploreWidget.defaultProps = {
  sortBy: "recent",
};
